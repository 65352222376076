.LottieElement {
    display: flex;
    align-items: center;
    justify-content: center;
    .earthquake-lottie {
        width: 55%;
    }
    .areas-lottie {
        width: 55%;
    }
    .prepList-lottie{
        width: 55%;
    }
}
