.ImageAndContent {
    width: 100vw;
    &__block {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        width: 100%;
        height: 100%; 
        align-items: center;
        justify-content: space-around; 
        @media screen and (min-width: 1024px) {
            flex-direction: row;
            min-height: 90vh;
            max-height: 90vh;
        }
        @media screen and (min-width: 1280px) {
            padding-left: 1%;
            padding-right: 1%;
        }
    }
    .image-first {
        background-color: #F8FAFF;
        @media screen and (min-width: 1280px) {
            padding-right: 1%;
            padding-left: 1%;
        }
        .ImageAndContent__content {            
            @media screen and (min-width: 1024px) {
                order: 2 !important;
            }
        }
        .ImageAndContent__image {
            @media screen and (min-width: 1024px) {
                width: 40%;
                order: 1 !important;
            }
        }
        
    }
    .content-first {
        @media screen and (min-width: 1280px) {
            padding-right: 1%;
            padding-left: 1%;
        }
        
    }
    &__content {
        text-align: center;
        @media screen and (min-width: 801px) and (max-width: 1000px) {
            padding: 5% 25%;
        }
        @media screen and (min-width: 1024px) {
            width: 50%;
            height: 80vh;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            padding: 5%;
        }
        &__header {
            justify-content: flex-start;
            font-weight: 600;
            padding: 5% 0;
            font-size: 1.25rem;
            @media screen and (min-width: 1024px) {
                font-size: calc(1.5rem + 1vw);
            }
        }
        &__parag {
            font-weight: 300;
            color: #4F596E;
            margin-bottom: 2rem;
            @media screen and (min-width: 1024px) {
                font-size: calc(1rem + 0.25vw);
                width: 95%;
            }
        }
    }
    &__image {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        @media screen and (min-width: 1024px) {
            width: 40%;
        }
        @media screen and (min-width: 1280px) {
            padding-left: 1%;
            padding-right: 1%;
        }
        @media screen and (max-height: 1200px) {
            max-width: 750px;
        }
        // img {
        //     transform: translateX(-10px);
        //     width: 70%;
        //     @media screen and (min-width: 1024px) and (max-width: 1279px) {
        //         width: 70%;
        //     }
        //     @media screen and (min-width: 1280px) {
        //         width: 60%;
        //     }
        // }
    }
}
