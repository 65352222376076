.StoreButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    & > span {
        max-width: 40%;
    }
    & > span:first-child {
        margin-right: 1rem;
    }
    & > span {
        cursor: pointer;
        &:hover {
            filter: drop-shadow(0.5rem 0.5rem 1rem rgba(189, 179, 180, 0.932));
            transform: translateY(3px);
        }
    }
    
}
