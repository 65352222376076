.PrivacyPolicy {
    h2 {
        font-size: 20px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    h3 {
        font-size: 16px;
        margin-top: 5px;
        margin-bottom: 3px;
    }
    p{
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    ul{
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 2px;
        li{
            display: list-item;
            list-style-type: disc;     /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
            list-style-position: inside;
            margin-left: 16px;
            margin-top: 2px;
            margin-bottom: 2px;
        }
    }
    .font-bold {
        font-weight: 700 !important;
    }
}
