.Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 75vh;
    min-height: 75vh;
    padding: 0px 5%;
    background-color: #061636;
    @media screen and (min-width: 1024px) {
        height: 33vh;
        min-height: 33vh;
        padding: 3%;
        flex-direction: row;
        justify-content: flex-start; 
    }
    &__qr {
        display: none;
        @media screen and (min-width: 1024px) {
            display: flex;
            width: 130px;
        }
    }
    &__logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 100%;
        width: 100%;
        @media screen and (min-width: 1024px) {
            align-items: flex-start;
            justify-content: space-between;
            width: 45%;
            height: 100%;
        }
    }
    &__links {
        display: flex;
        width: 100%;
        height: 100%;
        color: #ffffff;
        align-items: center;
        @media screen and (min-width: 1024px) {
            width: 30%;
        }
        .social-container {
            justify-content: space-between;
        }
        .link-title {
            text-align: left;
            margin-bottom: 1rem;
        }
        .link-subtitle {
            margin-bottom: 1rem;

        }
        &__company {
            display: flex;
            flex-direction: column;
            flex-basis: 45%;
            height: 100%;
        }
        &__social {
            display: flex;
            flex-direction: column;
            flex-basis: 50%;
            height: 100%;
        }
        & a {
            width: fit-content;
            border-bottom: 1px solid transparent;
            transition: all 0.2s ease;
            &:hover {
                border-bottom: 1px solid #0a59c3;
            }
        }
    }
    &__rights__mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 20%;
        padding-top: 3%;
        color: #ffffff;
        @media screen and (min-width: 1024px) {
            display: none;
        }
        span {
            color: #929bbf;
            margin: 0.5rem auto;
            @media screen and (min-width: 1024px) {
                margin: 0;
            }
        }
        @media screen and (min-width: 640px) {
            width: 60%;
            margin: 0 auto;
        }
        @media screen and (min-width: 1024px) {
            padding-top: 0;
        }
    }
    &__rights__desktop {
        color: #ffffff;
        @media screen and (max-width: 1023px) {
            display: none;
        }
    }
    .storebutton-desktop {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 25%;
        height: 100%;
        .download-title {
            color: #ffffff;
            font-weight: 700;
            display: flex;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 3rem;
        }
        .StoreButton {
            flex-direction: column;
            span {
                margin-right: 0rem;
                margin-top: 1rem;
            }
            img {
                max-width: unset;
                width: 9rem;
                height: auto;
            }
        }
        @media screen and (max-width: 1023px) {
            display: none;
        }
    }
    .storebutton-mobile {
        width: 75%;
        display: flex;
        align-items: center;
        justify-content: center;
        .StoreButton {
            justify-content: space-between;
        }
        @media screen and (min-width: 1024px) {
            display: none;
        }
    }
    
}
