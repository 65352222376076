.NavigationBar {
    display: flex;
    width: 100vw;
    min-height: 8vh;
    align-items: center;
    position: fixed;
    overflow: visible;
    border-bottom: 1px solid #dbe1ee;
    top: 0;
    left: 0;
    z-index: 100;
    justify-content: space-between;
    background-color: #ffffff;
    @media screen and (min-width: 1024px) {
        padding: 0 5%;
    }
    @media screen and (min-width: 1024px) {
        & > .NavigationBar__logo {
            flex-basis: 20%;
        }
        & > .NavigationBar__links {
            flex-basis: 50%;
        }
    }
    
    &__links {
        display: none;
        @media screen and (min-width: 1024px) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 10vh;
        }

        & > ul {
            display: none;
            width: 90%;
            align-self: stretch;
            justify-content: space-evenly;
            @media screen and (min-width: 1024px) {
                display: flex;
            }
            & a {
                font-weight: 600;
                font-size: 1vw;
            }
            & > li {
                align-self: stretch;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 2.5px solid transparent;
                &.selected-item {
                    border-bottom: 2.5px solid #0a59c3;
                    border-radius: 1.5px;
                    transition: all 0.4s ease;
                }
            }
            & .download {
                border-radius: 4px;
                background-color: #0a59c3;
                color: white;
                align-self: center;
                padding: 0.5rem 2.25rem;
                & > a {
                    font-weight: 300;
                }
            }
        }
        &-mobile {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media screen and (min-width: 1024px) {
                display: none;
            }
        }
    }
    &__logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 35%;
        @media screen and (max-width: 1023px) {
            padding-left: 3%;
        }
        @media screen and (min-width: 1280px) {
            width: 30%;
        }
        
        .link {
            @media screen and (max-width: 1023px) {
                display: none;
            }
        }
    }
        display: flex;
        margin-right: 1rem;
        align-self: flex-end;
        align-items: center;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            width: 100%;
        }    
        .link {
            font-weight: 600;
            border-bottom: 2.5px solid transparent;
        }
        .selected-item {
            color: #4F63FF;
            border-bottom: 2.5px solid #4F63FF;
            transition: all 0.4s ease;
        }
    &__menu {
        padding: 0 3%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .close-icon {
            position: absolute;
        }
        .hamburger-icon {
            position: absolute;
        }
        @media screen and (min-width: 1024px) {
           display: none; 
        }
    }
   
    &__side {
        position: absolute;
        z-index: 1;
        top: 10vh;
        width: 100vw;
        height: 90vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 1rem;
        background-color: #ffffff;
        .links {
            font-weight: 600;
            font-size: 1.1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .link {
                margin-bottom:1rem;
            }
        }
        .store-and-language {
            display: flex;
            position: absolute;
            bottom: 2rem;
            justify-content: center;
            width: 100%;
            font-size: 0.85rem;
            @media screen and (min-width: 768px) and (max-width: 1023px) {
                width: 50%;
            }
        }
    }
    .StoreButton {
        width: 75% !important; 
    }
}
