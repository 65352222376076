.DownloadPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 10vh;

    &__frame {
        display: flex;
        flex: 1;
        width: 100%;
        position: relative;
        &__content,
        &__visual {
            flex-basis: 50%;
        }
        &__content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            z-index: 9;
            padding: 0 5%;
            &__title {
                width: 100%;
                font-size: calc(1.5rem + 1vw);
                font-weight: 600;
                font-family: 'Nunito Sans', sans-serif;
                margin-bottom: 10vh;
                text-align: left;
            }
            &__links {
                display: flex;
                width: 100%;
                &__qr {
                    margin-right: 2rem;
                    & img {
                        width: 150px;
                    }
                }
                &__store {
                    font-weight: 300;
                    font-size: calc(0.5rem + 0.75vw);
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    text-align: left;
                }
            }
        }
        &__visual {
            z-index: 9;
            padding: 5% 0 0 0;
            & img {
                width: 90%;
                height: auto;
                margin: 0 auto;
            }
        }
        & > .ellipeleft {
            position: absolute;
            top: 10vh;
            left: 0;
            pointer-events: none;
            z-index: 1;
        }
        & > .ellipseright {
            position: absolute;
            right: 0;
            bottom: 0;
            pointer-events: none;
            z-index: 1;
        }
    }

}
