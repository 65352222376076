.Hero {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 100%;
    margin-top: 7vh;
    align-items: center;
    justify-content: space-around;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        min-height: 90vh;
        max-height: 90vh;
        margin-top: 10vh;
        height: 100%;
    }

    @media screen and (min-width: 1280px) {
        padding-left: 1%;
        padding-right: 1%;
    }

    &__lottie {
        width: 55%;
    }

    &__content {
        text-align: center;

        @media screen and (min-width: 801px) and (max-width: 1000px) {
            padding: 5% 25%;
        }

        @media screen and (min-width: 1024px) {
            width: 50%;
            height: 80vh;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;

        }

        .StoreButton {
            @media screen and (min-width: 1024px) {
                display: none;

            }
        }

        &__qr {
            display: none;

            @media screen and (min-width: 1024px) {
                display: flex;
                width: 150px;
            }
        }

        &__header {
            justify-content: flex-start;
            font-weight: 600;
            padding: 5% 0;
            font-size: 1.25rem;

            @media screen and (min-width: 1024px) {
                font-size: calc(1.5rem + 1vw);
            }
        }

        &__parag {
            font-weight: 300;
            color: #4F596E;
            margin-bottom: 2rem;

            @media screen and (min-width: 1024px) {
                font-size: calc(1rem + 0.25vw);
                width: 85%;
            }
        }

        .mouse-icon {
            width: 5%;
            height: 10%;
            display: flex;
            align-items: flex-end;

            @media screen and (max-width: 1023px) {
                display: none;
            }
        }

        &__top {
            padding: 5%;

            @media screen and (min-width: 1024px) {
                padding: 10%;
                height: 90%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    &__image {
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: 1024px) {
            width: 40%;


        }

        @media screen and (min-width: 1280px) {
            padding-left: 1%;
            padding-right: 1%;
        }

        @media screen and (max-height: 1200px) {
            max-width: 750px;
        }

        // img {
        //     transform: translateX(-10px);
        //     width: 75%;
        //     @media screen and (min-width: 1024px) and (max-width: 1279px) {
        //         width: 75%;
        //     }
        //     @media screen and (min-width: 1280px) {
        //         width: 65%;
        //     }
        // }
    }
}